import React, { useEffect, useState, memo } from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { Loading } from "components";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "store/user/operation";
import {
    Categories,
    Navigation,
    Projects,
    ChangePassword,
    Email,
    EditPage,
} from "./pages";
import EditProject from "./pages/Projects/Edit/EditProject";
import AddProject from "./pages/Projects/Add/AddProject";

const Admin = () => {
    const { is_auth } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const login = async () => {
            await dispatch(checkLogin());
            setLoading(false);
        };
        login();
    }, [dispatch]);

    if (loading) return <Loading />;
    if (!is_auth) return <Redirect to="/login" />;

    return (
        <div className="container">
            <Switch>
                <Route path="/admin" exact component={Navigation} />
                <Route path="/admin/kategorie" exact component={Categories} />

                <Route path="/admin/powiadomienia" exact component={Email} />
                <Route path="/admin/logowanie" exact component={Navigation} />
                <Route
                    path="/admin/projekty/projekt/:id"
                    component={EditProject}
                />
                <Route path="/admin/projekty/dodaj" component={AddProject} />
                <Route path="/admin/projekty" exact component={Projects} />
                <Route path="/admin/strony" exact component={EditPage} />
                <Route
                    path="/admin/zmiana-hasla"
                    exact
                    component={ChangePassword}
                />
            </Switch>
        </div>
    );
};

export default memo(Admin);
