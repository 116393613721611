import React from "react";
import { Wrapper } from "./buttons.css";
import {
    BiEditAlt,
    BiTrash,
    BiSave,
    BiXCircle,
    BiAddToQueue,
    BiBookHeart,
    BiBookAdd,
    BiLeftArrowCircle,
} from "react-icons/bi";
import { MdOutlineBookmarkAdd } from "react-icons/md";
const Button = (props) => {
    let ico = null;
    switch (props.ico) {
        case "edit":
            ico = <BiEditAlt />;
            break;
        case "trash":
            ico = <BiTrash />;
            break;
        case "save":
            ico = <BiSave />;
            break;
        case "cancel":
            ico = <BiXCircle />;
            break;
        case "add":
            ico = <BiAddToQueue />;
            break;
        case "cover":
            ico = <BiBookHeart />;
            break;
        case "addProject":
            ico = <BiBookAdd />;
            break;
        case "addCategory":
            ico = <MdOutlineBookmarkAdd />;
            break;
        case "back":
            ico = <BiLeftArrowCircle />;
            break;

        default:
    }

    return (
        <Wrapper {...props}>
            {ico}
            <p className="title">{props.children}</p>
        </Wrapper>
    );
};

export default Button;
