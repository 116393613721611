import React, { useEffect, useState } from "react";
import { Items, Wrapper } from "./selectCategories.css";
import { Button, Loading } from "components";
import { BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const SelectCategories = ({ id }) => {
    const [load, setLoad] = useState(true);
    const [projectCategories, setProjectCategories] = useState([]);
    const [categoriesItem, setCategoriesItem] = useState([]);
    const { auth } = useSelector((state) => state.user);

    async function get_project_categories(id) {
        setLoad(true);
        await fetch(`${process.env.REACT_APP_API_URL}?procat=getcat&id=${id}`)
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    setProjectCategories(res.project_category);
                }
            });
        setLoad(false);
    }

    async function get_categories() {
        await fetch(`${process.env.REACT_APP_API_URL}?category=gets`)
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    setCategoriesItem(res.categories);
                }
            });
        setLoad(false);
    }
    useEffect(() => get_project_categories(id), [id]);
    useEffect(() => get_categories(), []);

    const deleteCategory = (e) => {
        var formData = new FormData();
        formData.append("id", e);
        fetch(`${process.env.REACT_APP_API_URL}?procat=delete`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    get_project_categories(id);
                }
            });
    };

    const addProjectCategory = (e) => {
        var formData = new FormData();
        formData.append("id_category", e);
        formData.append("id_project", id);
        fetch(`${process.env.REACT_APP_API_URL}?procat=add`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    get_project_categories(id);
                }
            });
    };
    const test = () => {
        Swal.fire({
            title: "Kategorie do dodania",
            input: "select",
            inputOptions: categoriesItem,
            inputPlaceholder: "Wybierz kategorię",
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    addProjectCategory(value);
                    resolve();
                });
            },
        });
    };

    return (
        <Wrapper>
            <Items items={projectCategories}>
                {Object.keys(projectCategories).map((item, i) => (
                    <li key={item}>
                        {projectCategories[item].name}
                        <button
                            title="Usuń kategorię"
                            onClick={() =>
                                deleteCategory(projectCategories[item].id)
                            }>
                            <BiTrash />
                        </button>
                    </li>
                ))}
                {load && <Loading small dark />}
            </Items>
            <Button
                ico="addCategory"
                title="Dodaj kategorię do projektu"
                onClick={() => test()}>
                Dodaj
            </Button>
        </Wrapper>
    );
};

export default SelectCategories;
