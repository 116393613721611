import React, { useState, useEffect } from "react";
import { Button, Form, Loading, Page } from "components";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const DataBase = (props) => {
    const { auth } = useSelector((state) => state.user);
    const [load, setLoad] = useState(false);
    const [host, setHost] = useState("");
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [port, setPort] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        const getSeting = async (e) => {
            setLoad(true);
            await fetch(`${process.env.REACT_APP_API_URL}?config-email=get`, {
                method: "GET",
                headers: {
                    auth,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        setHost(res.email_config.host);
                        setLogin(res.email_config.login);
                        setPassword(res.email_config.password);
                        setPort(res.email_config.port);
                        setName(res.email_config.name);
                        setEmail(res.email_config.email);
                    }
                });

            setLoad(false);
        };
        getSeting();
    }, [auth]);

    const handleSaveDb = (e) => {
        e.preventDefault();

        setLoad(true);
        var formData = new FormData();
        formData.append("host", host);
        formData.append("login", login);
        formData.append("password", password);
        formData.append("port", port);
        formData.append("name", name);
        formData.append("email", email);
        fetch(`${process.env.REACT_APP_API_URL}?config-email=update`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    Swal.fire(
                        "Poczta powiadomień",
                        "Ustawienia poczty zostały poprawnie zmienione",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Poczta powiadomień",
                        "Ustawienia poczty nie zostały zmienione",
                        "error"
                    );
                }
            });

        setLoad(false);
    };

    return (
        <Page {...props} title="Ustawienia poczty wysyłającej powiadomienia">
            <Form onSubmit={handleSaveDb}>
                <label htmlFor="name">Wyświetlana nazwa w nagłówku:</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label htmlFor="email">E-mail:</label>
                <input
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="host">Serwer poczty SMTP:</label>
                <input
                    type="text"
                    name="host"
                    value={host}
                    onChange={(e) => setHost(e.target.value)}
                />
                <label htmlFor="database">Port:</label>
                <input
                    type="text"
                    name="database"
                    id="database"
                    value={port}
                    onChange={(e) => setPort(e.target.value)}
                />
                <label htmlFor="login">login:</label>
                <input
                    type="text"
                    name="login"
                    id="login"
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                />

                <label htmlFor="password">Hasło:</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {load ? (
                    <Loading className="mt-3" small dark title="Zapisywanie" />
                ) : (
                    <Button ico="save">Zapisz</Button>
                )}
            </Form>
        </Page>
    );
};

export default DataBase;
