import styled from "styled-components";

export const Wrapper = styled.div`
    height: ${(props) => (props.full ? "85vh" : "auto")};
    flex-direction: ${(props) => (props.small ? "row" : "column")};
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: ${(props) => (props.small ? "1.5em" : "5em")};
        color: ${(props) => (props.dark ? props.theme.color.bg : "white")};
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: center center;
    }

    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Label = styled.p`
    color: ${(props) => (props.dark ? props.theme.color.bg : "white")};
    font-size: ${(props) => (props.small ? "1em" : "1.2em")};
    margin-left: 5px;
`;
