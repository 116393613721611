import React, { useEffect, useState } from "react";
import { Loading } from "components";
import Category from "./Components/Category";
import { Wrapper } from "./categories.css";

const Categories = () => {
    const [categoryItem, setCategoryItem] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        async function get_navigation() {
            await fetch(
                `${process.env.REACT_APP_API_URL}?projects=get_category`
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        setCategoryItem(res.categories);
                    } else {
                    }
                });

            setLoad(false);
        }
        get_navigation();
        return () => {
            setCategoryItem([]);
        };
    }, []);

    if (load) return <Loading full />;
    return (
        <Wrapper>
            {Object.keys(categoryItem).map((item, i) => (
                <Category key={i} item={categoryItem[item]} />
            ))}
        </Wrapper>
    );
};

export default Categories;
