import React from "react";
import { Wrapper, Header, Content } from "./page.css";
import { Button } from "components";
const Page = (props) => {
    return (
        <Wrapper {...props}>
            <Header>
                <h3>{props.title}</h3>
                <Button ico="back" onClick={props.history.goBack}>
                    POWRÓT
                </Button>
            </Header>
            <Content>{props.children}</Content>
        </Wrapper>
    );
};

export default Page;
