import { Button, Form, Page, TextEditor } from "components";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const EditPage = (props) => {
    const [content, setContent] = useState("");
    const [name, setName] = useState("contact");
    const { auth } = useSelector((state) => state.user);

    useEffect(() => {
        get_navigation(name);
        return () => {};
    }, [name]);

    async function get_navigation(name) {
        await fetch(`${process.env.REACT_APP_API_URL}${name}.txt`)
            .then((res) => res.text())
            .then((res) => {
                setContent(res);
            });
    }
    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append("name", name);
        formData.append("content", content);
        return fetch(`${process.env.REACT_APP_API_URL}?page=update`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    Swal.fire("Zapisano stronę", name, "success");
                }
            });
    };
    return (
        <Page {...props} title="Edycja stron">
            <Form full white onSubmit={handleUpdate}>
                <label>Wybierz stronę</label>
                <select onChange={(e) => handleChangeName(e)}>
                    <option value="contact">Kontakt</option>
                    <option value="abaut">O mnie</option>
                </select>
                <label>Zawartość strony</label>
                <TextEditor newText={(e) => setContent(e)} text={content} />
                <Button ico="save">Zapisz</Button>
            </Form>
        </Page>
    );
};

export default EditPage;
