import styled from "styled-components";

export const Wrapper = styled.div`
    display: ${(props) => (props.show ? "flex" : "none")};
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    /* padding: 7%; */
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const Window = styled.div`
    height: 80%;
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 15px 15px 20px ${(props) => props.theme.color.shadow};
`;
