import React, { useEffect, useState } from "react";
import { Loading, Table, Title, Button } from "components";
import RowProject from "./RowProject";

import AddProject from "./Add/AddProject";
const Projects = (props) => {
    const [projectItems, setProjectItems] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        async function get_navigation() {
            await fetch(`${process.env.REACT_APP_API_URL}?projects=get_all`)
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        setProjectItems(res.projects);
                    }
                });

            setLoad(false);
        }
        get_navigation();
        return () => {
            setProjectItems([]);
        };
    }, []);

    return (
        <>
            <Title>
                Projekty
                <Button
                    className="ab-rc"
                    ico="back"
                    onClick={props.history.goBack}>
                    POWRÓT
                </Button>
            </Title>
            {load && <Loading />}
            <Table>
                <thead>
                    <tr>
                        <th>Zdjęcie</th>
                        <th colSpan="2">
                            Nazwa <AddProject />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(projectItems).map((item, i) => (
                        <RowProject key={i} item={projectItems[item]} />
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default Projects;
