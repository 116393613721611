import React from "react";
import { Wrapper, Footer, MyNavLink } from "./category.css";
import { BiCategory, BiLinkExternal } from "react-icons/bi";
import { Project, Title } from "components";

const Category = ({ item }) => {
    const { id, name, projects } = item;
    return (
        <Wrapper>
            <Title>
                <BiCategory />
                {name}
            </Title>
            <div className="content">
                {Object.keys(projects).map((project, i) => (
                    <Project id_image={i} key={i} project={projects[project]} />
                ))}
            </div>
            <Footer>
                <MyNavLink to={`kategoria/${id}`}>
                    <BiLinkExternal />
                    Więcej
                </MyNavLink>
            </Footer>
        </Wrapper>
    );
};

export default Category;
