import styled from "styled-components";

export const Wrapper = styled.h2`
    font-size: 1.5em;
    font-weight: 300;
    color: ${(props) => (props.dark ? "black" : "white")};
    text-transform: uppercase;
    max-width: 100%;
    position: relative;
    max-width: 100%;
    padding: 20px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.center ? "center" : "start")};
    font-size: 2em;
    font-weight: 3400;
    text-transform: uppercase;
    overflow: hidden;
    margin-left: 10px;
    & + svg {
        font-size: 2em;
        margin: 5px 10px 0 10px;
    }

    @media only screen and (max-width: 1024px) {
        padding: 20px;
        font-size: 1.7em;
        & + svg {
            font-size: 2rem;
        }
    }
    @media only screen and (max-width: 815px) {
        padding: 10px;
        font-size: 1.5em;
        & + svg {
            font-size: 1.8rem;
            margin: 3px 10px 0 0;
        }
    }
    @media only screen and (max-width: 450px) {
        padding: 5px;
        font-size: 1.6em;
        & + svg {
            font-size: 1.4rem;
            margin: 2px 10px 0 0;
        }
    }
`;
