import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    max-width: 1024px;
    min-height: 80vh;
    margin: 10px auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
`;
export const Header = styled.div`
    position: relative;
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    padding: 0 5px 0 15px;
    h3 {
        font-weight: 300;
    }
`;
export const Content = styled.div`
    padding: 20px;
`;
