import { Title } from "components";
import React from "react";
import {
    BiUser,
    BiCategory,
    BiChalkboard,
    BiEnvelope,
    BiLogOut,
    BiData,
} from "react-icons/bi";

import { Wrapper, MyNavLink } from "./navigation.css";

const Navigation = () => {
    return (
        <>
            <Title center>Ustawienia</Title>
            <Wrapper>
                <MyNavLink to="/admin/kategorie">
                    <div className="icon">
                        <BiCategory />
                    </div>
                    <p>Kategorie</p>
                </MyNavLink>
                <MyNavLink to="/admin/projekty">
                    <div className="icon">
                        <BiChalkboard />
                    </div>
                    <p>Projekty</p>
                </MyNavLink>
                <MyNavLink to="/admin/powiadomienia/">
                    <div className="icon">
                        <BiEnvelope />
                    </div>
                    <p>Powiadomienia</p>{" "}
                </MyNavLink>
                <MyNavLink to="/admin/zmiana-hasla/">
                    <div className="icon">
                        <BiUser />
                    </div>
                    <p>Zmiana hasła</p>
                </MyNavLink>
                <MyNavLink to="/admin/strony/">
                    <div className="icon">
                        <BiData />
                    </div>
                    <p>Edycja Stron</p>
                </MyNavLink>
                <MyNavLink to="/logout">
                    <div className="icon">
                        <BiLogOut />
                    </div>
                    <p>Wyloguj</p>
                </MyNavLink>
            </Wrapper>
        </>
    );
};

export default Navigation;
