import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "store/user/operation";
import localStorage from "store/localStore";
import { Redirect } from "react-router";
import { Wrapper } from "./login.css";
import { Button } from "components";
import { BiErrorCircle } from "react-icons/bi";
import cookies from "react-cookies";
const Login = () => {
    const [error, setError] = useState(false);
    const [is_auth, setIs_auth] = useState(false);
    const is_auth2 = useSelector((state) => state.user.is_auth);
    const dispatch = useDispatch();
    useEffect(() => {
        const login = async () => {
            await dispatch(checkLogin());
        };
        login();
    }, [dispatch]);

    async function fetchLogin(e) {
        e.preventDefault();
        var formData = new FormData();
        formData.append("name", e.target.login.value);
        formData.append("password", e.target.password.value);
        formData.append("device", cookies.load("device-token"));

        await fetch(`${process.env.REACT_APP_API_URL}?login`, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.user.is_auth) {
                    dispatch({ type: "SET_USER", items: res.user });
                    localStorage("auth", res.user.auth);
                    cookies.save("device-token", res.user.device_token, {
                        path: "/",
                    });
                    setIs_auth(true);
                } else {
                    setError(true);
                }
            });
    }

    return (
        <Wrapper>
            {is_auth && <Redirect to="/admin/" />}
            {is_auth2 && <Redirect to="/admin/" />}
            <p>Zaloguj się:</p>
            <form onSubmit={fetchLogin}>
                <label htmlFor="name">Login:</label>
                <input className="light" type="text" name="login" />
                <label htmlFor="password">Hasło:</label>
                <input className="light" type="password" name="password" />
                {error && (
                    <p className="error">
                        <BiErrorCircle /> Błędny login lub hasło
                    </p>
                )}
                <Button>Zaloguj</Button>
            </form>
        </Wrapper>
    );
};

export default Login;
