import React from "react";
import { Window, Wrapper } from "./modal.css";
const Modal = (props) => {
    return (
        <Wrapper {...props}>
            <Window>{props.children}</Window>
        </Wrapper>
    );
};

export default Modal;
