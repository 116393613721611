import React, { useEffect, useState } from "react";
import { Loading, Table, Title, Button } from "components";
import RowCategory from "./RowCategory";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
const Categories = (props) => {
    const [categoriesItem, setCategoriesItem] = useState([]);
    const [load, setLoad] = useState(true);
    const { auth } = useSelector((state) => state.user);

    useEffect(() => {
        async function get_categories() {
            await fetch(`${process.env.REACT_APP_API_URL}?category=get`)
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        setCategoriesItem(res.categories);
                    }
                });

            setLoad(false);
        }
        get_categories();
        return () => {
            setCategoriesItem([]);
        };
    }, []);

    const handleAddNewCategory = () => {
        Swal.fire({
            title: "Podaj nazwę nowej kategorii",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Dodaj",
            showLoaderOnConfirm: true,
            preConfirm: (name) => {
                var formData = new FormData();
                formData.append("name", name);

                return fetch(`${process.env.REACT_APP_API_URL}?category=add`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        auth,
                    },
                })
                    .then((response) => {
                        if (!response.status) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                setCategoriesItem(categoriesItem.concat(result.value.category));
            }
        });
    };
    if (load) return <Loading />;

    return (
        <>
            <Title>
                Kategorie
                <Button
                    className="ab-rc"
                    ico="back"
                    onClick={props.history.goBack}>
                    POWRÓT
                </Button>
            </Title>

            <Table>
                <thead>
                    <tr>
                        <th colspan="2">
                            Nazwa
                            <div className="df-rc mb-2 ab-rt">
                                <Button
                                    ico="add"
                                    onClick={() => handleAddNewCategory()}>
                                    Dodaj nową kategorię
                                </Button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(categoriesItem).map((item, i) => (
                        <RowCategory key={i} item={categoriesItem[item]} />
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default Categories;
