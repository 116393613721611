import { Button, Modal } from "components";
import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export default function App({ auth, category_key, projectId, changeImg }) {
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({
        x: 0,
        y: 0,
        unit: "%",
        width: 99.9,
        aspect: 4 / 3,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [modalCrop, setModalCrop] = useState(false);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setModalCrop(true);
            const reader = new FileReader();
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const setCropTimeOut = () => {
        setCrop({
            x: 0,
            y: 0,
            unit: "%",
            width: 99.9,
            aspect: 4 / 3,
        });
    };
    const onLoad = useCallback((img) => {
        imgRef.current = img;
        setTimeout(setCropTimeOut, 100);
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    function add_image(canvas, crop, auth, projectId) {
        if (!crop || !canvas) {
            return;
        }

        var formData = new FormData();
        canvas.toBlob(
            (blob) => {
                formData.append("id", projectId);
                formData.append("image", blob, "filename.jpeg");
                fetch(`${process.env.REACT_APP_API_URL}?project=image-add`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        auth,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        changeImg(res.images);
                        setCompletedCrop(null);
                        setModalCrop(false);
                    })
                    .catch((err) => {

                    });
            },
            "image/jpeg",
            0.95
        );
    }
    return (
        <div className="App">
            <div>
                <input type="file" accept="image/*" onChange={onSelectFile} />
            </div>

            <Modal show={modalCrop}>
                <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                />
                <div className="df-sbc">
                    <Button
                        ico="save"
                        type="button"
                        disabled={
                            !completedCrop?.width || !completedCrop?.height
                        }
                        onClick={() =>
                            add_image(
                                previewCanvasRef.current,
                                completedCrop,
                                auth,
                                projectId
                            )
                        }>
                        Prześlij zdjęcie
                    </Button>
                    <Button ico="cancel" onClick={() => setModalCrop(false)}>
                        Zamknij
                    </Button>
                </div>
            </Modal>

            <div className="">
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                    }}
                />
            </div>
        </div>
    );
}
