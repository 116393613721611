import React from "react";
import { Page } from "components";
import { Logo } from "./page404.css";
import { BiError } from "react-icons/bi";
const Page404 = (props) => {
    return (
        <Page {...props}>
            <Logo>
                <BiError /> <p>ERROR 404</p>
            </Logo>

            <p>Podana strona nie istnieje !!!</p>
        </Page>
    );
};

export default Page404;
