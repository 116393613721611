import React, { useState } from "react";
import { Button } from "components";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const RowCategory = ({ item }) => {
    const { name, id } = item;
    const [edit, setEdit] = useState(false);
    const [newName, setNewName] = useState(name);
    const [load, setLoad] = useState(false);
    const [deleteItem, setDelete] = useState(false);
    const { auth } = useSelector((state) => state.user);

    const handleSaveName = async () => {
        setLoad(true);
        var formData = new FormData();
        formData.append("name", newName);
        formData.append("id", id);
        await fetch(`${process.env.REACT_APP_API_URL}?category=update`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    setEdit(!edit);
                }
            });

        setLoad(false);
    };
    const handleDeleteName = async () => {
        Swal.fire({
            title: "Czy napewno chcesz usunąć:",
            text: newName,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Nie",
            confirmButtonText: "Tak",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoad(true);
                var formData = new FormData();
                formData.append("id", id);
                fetch(`${process.env.REACT_APP_API_URL}?category=delete`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        auth,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status) {
                            setDelete(true);
                        }
                    });
            }
        });

        setLoad(false);
    };

    const handleCancelName = () => {
        setNewName(name);
        setEdit(!edit);
    };

    if (deleteItem) return <></>;
    return (
        <tr>
            <td className="w70">
                {edit ? (
                    <>
                        <input
                            className="w100"
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </>
                ) : (
                    newName
                )}
            </td>
            <td className="df tr">
                {edit ? (
                    <>
                        <Button
                            ico="save"
                            green
                            smal
                            onClick={() => handleSaveName()}
                            disabled={load}>
                            {load ? "Zapisywanie" : "Zapisz"}
                        </Button>
                        <Button
                            ico="cancel"
                            smal
                            onClick={() => handleCancelName()}>
                            Anuluj
                        </Button>
                    </>
                ) : (
                    <Button ico="edit" smal onClick={() => setEdit(!edit)}>
                        Edytuj
                    </Button>
                )}

                <Button ico="trash" smal red onClick={() => handleDeleteName()}>
                    Usuń
                </Button>
            </td>
        </tr>
    );
};

export default RowCategory;
