import React from "react";
import { Wrapper, MyLink, Hamburger } from "./header.css";
import { BiHomeAlt, BiCog, BiInfoCircle, BiLogOut } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";

import { HiOutlineMail } from "react-icons/hi";
import { useSelector } from "react-redux";
const Header = () => {
    const { is_auth } = useSelector((state) => state.user);
    return (
        <Wrapper>
            <h1>VerticalStudio</h1>
            <div>
                <Hamburger>
                    <GiHamburgerMenu />
                </Hamburger>
                <nav>
                    <MyLink exact to="/">
                        <BiHomeAlt />
                        Strona Główna
                    </MyLink>

                    <MyLink to="/kontakt">
                        <HiOutlineMail />
                        Kontakt
                    </MyLink>

                    <MyLink to="/o-mnie">
                        <BiInfoCircle />O mnie
                    </MyLink>

                    {is_auth && (
                        <>
                            <MyLink to="/admin">
                                <BiCog />
                                Ustawienia
                            </MyLink>
                            <MyLink to="/logout" title="Wyloguj">
                                <BiLogOut />
                            </MyLink>
                        </>
                    )}
                </nav>
            </div>
        </Wrapper>
    );
};

export default Header;
