import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    width: 32%;
    aspect-ratio: 4 / 3;
    /* height: 350px; */
    margin: 5px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    /* border-radius: 8px; */
    border: 8px solid white;
    box-shadow: 0 0 8.4px rgba(0, 0, 0, 0.3);

    @media only screen and (max-width: 1248px) {
        width: 32%;
    }
    @media only screen and (max-width: 1024px) {
        width: 48%;
    }
    @media only screen and (max-width: 815px) {
        width: 95%;
    }

    :hover {
        img {
            filter: brightness(1.2);
            transform: scale(1.1);
        }
        .menu {
            transform: translateY(0);
        }
    }
    img {
        min-width: 100%;
        min-height: 100%;
        /* border-radius: 8px; */

        transition: transform 0.2s ease-in-out, filter 0.2s ease-in-out;
        cursor: pointer;
    }
    .cover {
        position: absolute;
        top: 5px;
        left: 5px;
        padding:  8px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);

        color: rgba(0, 0, 0, 0.8);
        svg {
            font-size: 32px;
        }
    }
    .menu {
        display: flex;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);
        z-index: 20;
    }
`;
