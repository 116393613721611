import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const TextEditor = ({ newText, text }) => {
    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={text}
                config={{
                    entities: false,
                    toolbar: {
                        items: [
                            "heading",
                            // "fontSize",
                            // "fontFamily",
                            // "|",
                            "alignment",
                            "fontColor",
                            "fontBackgroundColor",
                            "bold",
                            "italic",
                            "bulletedList",
                            "numberedList",
                            "link",
                            // "|",
                            // "indent",
                            // "outdent",
                            "|",
                            "blockQuote",
                            "insertTable",
                            "undo",
                            "redo",
                        ],
                        language: "pl",
                        // plugin: Font,
                    },
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    newText(data);
                }}
            />
        </>
    );
};

export default TextEditor;
