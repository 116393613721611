import styled from "styled-components";

export const Label = styled.label`
    display: block;
    margin: 15px 0 5px 0;
    font-size: 1.1em;
`;

export const ImagesBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0 5px 0;
    @media only screen and (max-width: 815px) {
        justify-content: center;
    }
`;
