import React, { useState } from "react";
import { Wrapper } from "./image.css";
import { Button } from "components";
import { useSelector } from "react-redux";
import { BiBookHeart } from "react-icons/bi";

const Image = ({ projectId, img, updateCover, imgCover }) => {
    const { auth } = useSelector((state) => state.user);
    const [isDelete, setDelete] = useState(false);
    const deleteImg = () => {
        var formData = new FormData();
        formData.append("id_image", img.id);
        fetch(`${process.env.REACT_APP_API_URL}?project=image-delete`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    setDelete(true);
                }
            });
    };
    const setCover = () => {
        var formData = new FormData();
        formData.append("id_image", img.id);
        formData.append("id_project", projectId);
        fetch(`${process.env.REACT_APP_API_URL}?project=image-cover`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    updateCover(img.id);
                }
            });
    };

    if (isDelete) return "";
    return (
        <Wrapper>
            <div className="menu">
                <Button onClick={() => setCover()} ico="cover">
                    Okładka
                </Button>
                <Button onClick={() => deleteImg()} red ico="trash">
                    Usuń
                </Button>
            </div>
            {imgCover === img.id && (
                <div className="cover">
                    <BiBookHeart />
                </div>
            )}
            <img
                src={`${process.env.REACT_APP_API_URL}${img.thumbnail}`}
                alt="Zdjęcie "
            />
        </Wrapper>
    );
};

export default Image;
