import styled from "styled-components";

export const Wrapper = styled.table`
    border-collapse: collapse;
    width: 100%;
    max-width: 100vw;
    border: 1px solid #ddd;
    box-shadow: 10px 10px 15px ${(props) => props.theme.color.shadow};
    td,
    th {
        /*  */
        padding: 8px;
        position: relative;
        &.dsc {
            text-align: center;
        }
        &.dsr {
            text-align: right;
        }
    }
    tr {
        background-color: white;
    }
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 300;
        background-color: ${(props) => props.theme.color.bg};
        color: white;
        @media only screen and (max-width: 815px) {
            text-align: left;
        }
    }
`;
