import React from "react";
import { Button } from "components";
import { NavLink } from "react-router-dom";
import { RowProjectTable } from "./projects.css";
const RowProject = ({ item }) => {
    const { title, id, thumbnail } = item;

    return (
        <RowProjectTable>
            <td className="tc c1">
                <img
                    className="thumbnail"
                    src={`${process.env.REACT_APP_API_URL}${
                        thumbnail === null
                            ? "galery/architect-thumbnail.jpeg"
                            : thumbnail
                    }`}
                    alt="Zdjęcie projektu"
                />
            </td>
            <td className="w90 c2">{title}</td>
            <td className="df-fc c3">
                <NavLink to={`/admin/projekty/projekt/${id}`}>
                    <Button smal ico="edit">
                        Edytuj
                    </Button>
                </NavLink>
            </td>
        </RowProjectTable>
    );
};

export default RowProject;
