import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  body,* {
   margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Segoe UI', 'Roboto', sans-serif;
    
  }
  body{ 
font-size: 16px;
    background-color: #16384c;
  }
  html {
    scroll-behavior: smooth;
  }
  ul , ol, li {
    margin-left:20px;
  }
  a{
    text-decoration: none;
    
  }
  .container{
    max-width: 1024px;
    margin: 0 auto;;
  }
  .hide
  {
    display:none;
  }
  p.error{
    color: ${(props) => props.theme.color.red.normal};
    padding: 15px 0 0 0;
  }
  .tl{
    text-align: left;
    justify-content: left;
  }

  .tc{
    text-align: center;
    justify-content: center;
  }
  .tr{
    text-align: right;
    justify-content: right;
  }
  .df{
    display: flex;
  }
  .df-cc{
    display:flex;
    justify-content:center;
    align-items: center;
  }
  .df-sbc{
    display:flex;
    justify-content:space-between;
    align-items: center;
  }

  .df-rc{
    display:flex;
    justify-content:flex-end;
    align-items: center;
  }
    .df-lc{
    display:flex;
    justify-content:flex-start;
    align-items: center;
  }
  .df-col{
    flex-direction: column;
  }
  .w70{
    width: 70%;
  }
  .w80{
    width: 80%;
  }
  .w90{
    width: 90%;
  }
  .w100{
    width: 100%;
  }
  
  .mb-2{
    margin-bottom: 5px;
  }
  .mb-3{
    margin-bottom: 8px;
  }
  .mt-3{
    margin-top: 8px;
  }


  input {
        padding: 10px;
        border : 1px solid ${(props) =>
            props.white ? "white" : props.theme.color.bg};
        border-radius: 10px;
        outline-style: none;
    }

    input[type=text],
    input[type=password]{

      &:active,
      &:focus,
      &:hover{
        border : 1px solid ${(props) => props.theme.color.main.normal};
      }

      &:active,
      &:focus{
       box-shadow: 0 0 8px -2px ${(props) => props.theme.color.main.normal};
      }

      &.error{
         border : 1px solid ${(props) => props.theme.color.red.normal};
        box-shadow: 0 0 8px -2px ${(props) => props.theme.color.red.normal};
      }
      &.light{

        border : 1px solid white;
      }

    }

  img.thumbnail{
    border-radius: 8px;
    width: 150px;
  }
  .swal2-styled.swal2-confirm {
    background:  ${(props) => props.theme.color.main.normal};
  }
  .swal2-styled.swal2-cancel {
      background:  ${(props) => props.theme.color.red.normal};
  }
  .swal2-styled.swal2-danger {
      background:  ${(props) => props.theme.color.red.normal};
  }
  .swal2-file, .swal2-input, .swal2-textarea{
    border: 1px solid ${(props) => props.theme.color.main.normal};
    &:focus{
      border: 1px solid ${(props) => props.theme.color.main.ligth};
      box-shadow: inset 0 0 6px ${(props) => props.theme.color.main.light};
    }
  }
  .ck-content{
    padding: 20px;
  }
  .ReactCrop {
		display: block;
		height: 90%;
		.ReactCrop__image {
			max-width: 70vw;
      max-height: 70vh;
		}
	}
  .hide{
    display: none;
  }
  .ab-rt{
    position: absolute;
    top: 0;
    right: 0;
  }
  .ab-rc{
    position: absolute;
    right: 0;
  }
  .w100{
    width:100% ;
  }
`;
export default GlobalStyle;
