import styled, { css } from "styled-components";

export const Wrapper = styled.form`
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
        margin: 20px 0 5px 0;
    }

    button {
        padding: 12px 0;
        margin: 20px 0;
    }
    @media only screen and (max-width: 815px) {
        width: 100%;
    }

    ${(props) =>
        props.full &&
        css`
            width: 100%;
        `}
    ${(props) =>
        props.white &&
        css`
            label {
                color: black;
            }
        `}
`;
