import React, { useEffect, useState } from "react";
import { Loading, Project, Title } from "components";
import { BiCategory } from "react-icons/bi";
import { Wrapper } from "./category.css";
const Category = (props) => {
    const [projectItems, setProjectItems] = useState([]);
    const [load, setLoad] = useState(true);
    const id = props.match.params.id;

    useEffect(() => {
        setLoad(true);
        async function get_navigation() {
            await fetch(
                `${process.env.REACT_APP_API_URL}?projects=get_by_category&category_id=${id}`
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        setProjectItems(res.projects);
                    } else {
                    }
                });

            setLoad(false);
        }
        get_navigation();
        return () => {
            setProjectItems([]);
        };
    }, [id]);

    if (load) return <Loading full />;
    return (
        <Wrapper>
            <Title>
                <BiCategory />
                {projectItems["name"]}
            </Title>

            <div className="content">
                {Object.keys(projectItems["items"]).map((project, i) => (
                    <Project
                        id_image={i}
                        key={i}
                        project={projectItems["items"][project]}
                    />
                ))}
            </div>
        </Wrapper>
    );
};

export default Category;
