import React, { useState, useEffect } from "react";
import {
    Button,
    Image,
    SelectFile,
    TextEditor,
    Loading,
    SelectCategories,
    Page,
} from "components";
import { useSelector } from "react-redux";
import { Label, ImagesBox } from "./editProject.css";
import Swal from "sweetalert2";

const Edit = (props) => {
    const id = props.match.params.id;
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [images, setImages] = useState([]);
    const [imgCover, setImgCover] = useState(null);
    const { auth } = useSelector((state) => state.user);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setLoad(true);
        async function get_navigation() {
            await fetch(`${process.env.REACT_APP_API_URL}?project=get&id=${id}`)
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        const { title, content, images, cover_id } =
                            res.project;
                        setTitle(title);
                        setContent(content);
                        setImages(images);
                        setImgCover(cover_id);
                    } else {
                    }
                });

            setLoad(false);
        }
        get_navigation();
        return () => {};
    }, [id]);

    const handleUpdate = () => {
        setLoad(true);
        var formData = new FormData();
        formData.append("id", id);
        formData.append("title", title);
        formData.append("content", content);
        return fetch(`${process.env.REACT_APP_API_URL}?project=update`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    Swal.fire("Zapisano projekt", title, "success");
                } else {
                }

                setLoad(false);
            });
    };

    const handleDelete = (props) => {
        Swal.fire({
            title: "Czy napewno chcesz usunąć",
            text: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Nie",
            confirmButtonText: "Tak",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoad(true);
                var formData = new FormData();
                formData.append("id", id);
                return fetch(
                    `${process.env.REACT_APP_API_URL}?project=delete`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            auth,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status) {
                            Swal.fire("Usunięto", title, "success");
                            props.history.goBack();
                        } else {
                        }
                        setLoad(false);
                    });
            }
        });
    };

    return (
        <Page {...props} title="Edycja projektu">
            {load ? (
                <Loading small dark title="Zapisywanie" />
            ) : (
                <div className="df-rc">
                    <Button ico="save" onClick={() => handleUpdate()}>
                        Zapisz
                    </Button>
                    <Button ico="trash" red onClick={() => handleDelete(props)}>
                        Usuń
                    </Button>
                </div>
            )}
            <Label>Tytuł</Label>
            <input
                className="w100"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <Label>Kategorie</Label>
            <SelectCategories id={id} />
            <Label className="mb-3">Dodaj opis:</Label>
            <TextEditor newText={(e) => setContent(e)} text={content} />
            <Label className="mb-3">Zdjęcia</Label>
            <ImagesBox>
                {Object.keys(images).map((item, i) => (
                    <Image
                        key={i}
                        projectId={id}
                        imgCover={imgCover}
                        img={images[item]}
                        updateCover={(e) => setImgCover(e)}
                    />
                ))}
            </ImagesBox>
            <SelectFile
                projectId={id}
                auth={auth}
                changeImg={(e) => setImages(e)}
            />
        </Page>
    );
};

export default Edit;
