import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "styled/theme";
import GlobalStyle from "styled/globalStyled";
import { Header } from "components";
import {
    HomePage,
    Categories,
    Category,
    Project,
    Login,
    Logout,
    Admin,
    Page404,
    Contact,
    Abaut,
} from "pages";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Header />
                <Route path="/" exact component={HomePage} />
                <Switch>
                    <Route path="/" exact component={Categories} />
                    <Route path="/kategoria/:id" exact component={Category} />
                    <Route path="/projekt/:id" exact component={Project} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/kontakt" component={Contact} />
                    <Route path="/o-mnie" component={Abaut} />
                    <Route component={Page404} />
                </Switch>
                <GlobalStyle />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
