import styled from "styled-components";

export const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-size: 2.7em;
    svg {
        font-size: 2.5em;
    }
    & + p {
        display: flex;
        justify-content: center;
        font-size: 2em;
    }
`;
