import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled(NavLink)`
    /* height: 250px; */
    border-radius: 10px;
    width: 350px;
    padding: 0 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    margin: 90px 10px 0 10px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
    transition: 0.3s ease-in-out;
    cursor: pointer;

    :hover {
        /* box-shadow: 0px -20px 30px 50px rgba(255, 255, 255, 0.3); */
        div.imgBx img {
            transform: scale(1.1);
            filter: sepia(0);
        }
    }
    div.imgBx {
        position: relative;
        display: flex;
        transform: translateY(-50px);
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border-radius: 10px;
        img {
            transition: 0.3s ease-in-out;
            filter: sepia(0.4);
            width: 100%;
        }
    }

    p {
        transform: translateY(-30px);
        font-size: 1.3rem;
        align-items: center;
        color: ${(props) => props.theme.color.main.dark};
    }

    @media only screen and (max-width: 1248px) {
        width: 350px;
    }
    @media only screen and (max-width: 1024px) {
        width: 300px;
        margin: 90px 7px 0 7px;
    }
    @media only screen and (max-width: 815px) {
        width: 200px;
        margin: 90px 3px 0 3px;
        padding: 0 15px;
        p {
            font-size: 1.2em;
        }
    }
    @media only screen and (max-width: 450px) {
        width: 95%;
        margin: 90px 5px 0 5px;
        p {
            font-size: 1.2em;
        }
    }
`;
