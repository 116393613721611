import React, { useEffect, useState } from "react";
import { Loading, Title, Page } from "components";
import { Article } from "./project.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const Project = (props) => {
    const [project, setProject] = useState([]);
    const [images, setImages] = useState([]);
    const [load, setLoad] = useState(true);
    const id = props.match.params.id;

    useEffect(() => {
        setLoad(true);
        async function get_navigation() {
            await fetch(`${process.env.REACT_APP_API_URL}?project=get&id=${id}`)
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        setProject(res.project);
                        const images = res.project.images.map((item) => ({
                            original: `${process.env.REACT_APP_API_URL}${item.original}`,
                            thumbnail: `${process.env.REACT_APP_API_URL}${item.thumbnail}`,
                        }));

                        setImages(images);
                    } else {
                    }
                });

            setLoad(false);
        }
        get_navigation();
        return () => {
            setProject([]);
        };
    }, [id]);

    if (load) return <Loading full />;
    return (
        <Page {...props}>
            <Title center dark>
                {project.title}
            </Title>
            <Article
                className="ck ck-content"
                dangerouslySetInnerHTML={{ __html: project.content }}></Article>
            {images.length > 0 && (
                <ImageGallery items={images} autoPlay="true" />
            )}
        </Page>
    );
};

export default Project;
