import styled from "styled-components";
import { NavLink } from "react-router-dom";
export const Wrapper = styled.div`
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    @media only screen and (max-width: 1024px) {
    }
    @media only screen and (max-width: 815px) {
        margin-top: 10px;
        padding: 0;
    }
    @media only screen and (max-width: 450px) {
    }
    /* background-color: wheat; */
`;

export const MyNavLink = styled(NavLink)`
    position: relative;
    width: 250px;
    height: 150px;
    border: 4px solid white;
    border-radius: 20px;
    margin: 15px;
    padding: 10px;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    /* align-content: center; */
    cursor: pointer;

    .icon {
        top: 0;
        position: absolute;
        background-color: ${(props) => props.theme.color.bg};
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: 0.3s ease-in-out;

        svg {
            font-size: 2em;
            font-weight: 100;
            transition: 0.3s ease-in-out;
            transform: translateY(-15px);
        }
    }
    :hover .icon {
        width: 60px;
        height: 60px;
        top: 15px;
        border-radius: 50%;
        svg {
            transform: translateY(0);
        }
    }
    p {
        position: absolute;
        text-align: center;
        font-size: 1em;
        bottom: 20px;
        text-transform: uppercase;
        color: white;
        transition: 0.25s ease-in-out;
    }
    :hover p {
        font-size: 1.5em;
        color: ${(props) => props.theme.color.bg};
    }
    @media only screen and (max-width: 1024px) {
        margin: 20px;
        font-size: 1.2em;
    }
    @media only screen and (max-width: 815px) {
        margin: 5px;
        font-size: 1.1em;
        width: 46%;
    }
`;
