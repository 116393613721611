import { Page } from "components";
import React, { useState, useEffect } from "react";

const Contact = (props) => {
    const [content, setContent] = useState("");
    useEffect(() => {
        async function get_navigation() {
            await fetch(`${process.env.REACT_APP_API_URL}contact.txt`)
                .then((res) => res.text())
                .then((res) => {
                    setContent(res);
                });
        }
        get_navigation();

        return () => {};
    }, []);

    return (
        <Page {...props} title="Kontakt">
            <div
                className="ck ck-content"
                dangerouslySetInnerHTML={{ __html: content }}></div>
        </Page>
    );
};

export default Contact;
