import styled from "styled-components";
import { NavLink } from "react-router-dom";
export const Wrapper = styled.header`
    position: relative;
    padding: 10px 50px;
    box-shadow: 3px 3px 3px ${(props) => props.theme.color.shadow};
    background: white;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 300;
        color: ${(props) => props.theme.color.bg};
    }
    nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    @media only screen and (max-width: 815px) {
        flex-direction: column;
        padding: 10px;
        font-size: small;
    }
    @media only screen and (max-width: 500px) {
        flex-direction: row;
    }
`;

export const MyLink = styled(NavLink)`
    text-transform: uppercase;
    color: ${(props) => props.theme.color.bg};
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px;
    svg {
        font-size: 1.1em;
        margin-right: 3px;
    }
    &.active {
        color: ${(props) => props.theme.color.main.normal};
        border-bottom: 2px solid ${(props) => props.theme.color.main.normal};
    }
    :hover {
        color: ${(props) => props.theme.color.main.normal};
    }
    @media only screen and (max-width: 815px) {
        margin-right: 10px;
        padding: 10px 5px;
    }
    @media only screen and (max-width: 500px) {
        margin-right: 0;
        padding: 10px 5px;
        margin: 3px 10px;
        font-size: 1.5em;
        &.active {
            border-left: 3px solid ${(props) => props.theme.color.main.normal};
            margin-left: 15px;
            border-bottom: none;
        }
    }
`;

export const Hamburger = styled.button`
    display: none;
    font-size: 2em;
    color: ${(props) => props.theme.color.bg};
    background-color: white;
    padding: 7px 10px;
    border: none;
    @media only screen and (max-width: 500px) {
        display: inline;

        & + nav {
            transform: translateX(-100%);
            transition: transform 0.4s ease-in-out;
            width: 100%;
            position: absolute;
            left: 0;
            flex-direction: column;
            align-items: flex-start;
            background-color: whitesmoke;
            padding: 10px 20px 10px 10px;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
            z-index: 1000px;
        }

        &:focus {
            & + nav {
                transform: translateX(0);
            }
        }
    }
`;
