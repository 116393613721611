import React, { useState } from "react";
import { Button, Loading, Page, Form } from "components";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const ChangePassword = (props) => {
    const { auth } = useSelector((state) => state.user);

    const [load, setLoad] = useState(false);
    const [error, setError] = useState("");

    const validatePassword = (password, newpassword, renewpassword) => {
        setError("");

        var p = password.value,
            pn = newpassword.value,
            rep = newpassword.value,
            errors = [];

        if (p === "" || pn === "" || rep === "") {
            errors.push("Wypełnij wszystkie pola");
        }
        if (pn !== rep) {
            errors.push("Hasła różnią się");
        }
        if (pn.length < 8) {
            errors.push("Minimum 8 znaków.");
        }
        if (pn.search(/[a-zA-Z]/i) < 0) {
            errors.push("Conajmniej jedną małą lub dużą literę.");
        }
        if (pn.search(/[0-9]/) < 0) {
            errors.push("Conajmniej jedną cyfrę.");
        }
        if (pn.search(/[!#$%&?@"]/) < 0) {
            errors.push('Conajmniej jeden znak specialny !#$%&?@"');
        }
        if (errors.length > 0) {
            setError(errors.join("<br/>"));
            password.classList.add("error");
            newpassword.classList.add("error");
            renewpassword.classList.add("error");
            return false;
        }
        return true;
    };

    const handleSavePassword = async (e) => {
        e.preventDefault();

        const [password, newpassword, renewpassword] = e.target.elements;

        if (!validatePassword(password, newpassword, renewpassword)) return;

        // const new_password = e.target.
        setLoad(true);
        var formData = new FormData();
        formData.append("password", password.value);
        formData.append("new_password", newpassword.value);
        await fetch(`${process.env.REACT_APP_API_URL}?change-password`, {
            method: "POST",
            body: formData,
            headers: {
                auth,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    Swal.fire(
                        "Zmiana hasła",
                        "Hasło zostało poprawnie zmienione",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Zmiana hasła",
                        "Hasło nie zostało zmienione",
                        "error"
                    );
                }
            });

        setLoad(false);
    };

    return (
        <Page {...props} title="Zmiana hasła">
            <Form onSubmit={handleSavePassword}>
                <label htmlFor="password">Podaj hasło:</label>
                <input
                    type="password"
                    name="password"
                    autoComplete="password"
                />
                <label htmlFor="newpassword">Podaj nowe hasło:</label>
                <input
                    type="password"
                    name="newpassword"
                    id="newpassword"
                    autoComplete="newpassword"
                />
                <label htmlFor="renewpassword">Powrtórz nowe hasło:</label>
                <input
                    type="password"
                    name="renewpassword"
                    id="renewpassword"
                    autoComplete="renewpassword"
                />
                <p
                    className="error"
                    dangerouslySetInnerHTML={{ __html: error }}></p>
                {load ? (
                    <Loading className="mt-3" small dark title="Zapisywanie" />
                ) : (
                    <Button ico="save">Zapisz</Button>
                )}
            </Form>
        </Page>
    );
};

export default ChangePassword;
