import React from "react";
import { Button } from "components";
import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
const AddProject = () => {
    const { auth } = useSelector((state) => state.user);
    let history = useHistory();
    const handleAddNewProject = () => {
        Swal.fire({
            title: "Podaj nazwę nowego projektu",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Dodaj",
            showLoaderOnConfirm: true,
            preConfirm: (title) => {
                var formData = new FormData();
                formData.append("title", title);

                return fetch(`${process.env.REACT_APP_API_URL}?project=add`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        auth,
                    },
                })
                    .then((response) => {
                        if (!response.status) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                history.push(
                    `/admin/projekty/projekt/${result.value.project.id}`
                );
            }
        });
    };
    return (
        <div className="df-rc mb-2 ab-rt">
            <Button ico="addProject" onClick={() => handleAddNewProject()}>
                Dodaj nowy projekt
            </Button>
        </div>
    );
};

export default AddProject;
