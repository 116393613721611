import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const Items = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    li {
        margin: 0 5px 0 0;
        list-style: none;
        padding: 4px 6px;
        display: flex;
        justify-content: center;
        border: 1px solid ${(props) => props.theme.color.main.normal};
        border-radius: 6px;
        line-height: 20px;

        button {
            border: none;
            padding: 2px 2px 0 2px;
            background-color: transparent;
            svg {
                height: 16px;
            }
            &:hover {
                svg {
                    color: red;
                }
            }
        }
        &::before {
            content: "#";
        }
    }
`;

export const Select = styled.select``;
