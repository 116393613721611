import styled from "styled-components";

export const Article = styled.div`
    margin-bottom: 30px;
    font-size: 1.2em;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
        font-size: 1.2em;
    }
    @media only screen and (max-width: 815px) {
        margin-bottom: 10px;
        font-size: 1.1em;
    }
    @media only screen and (max-width: 450px) {
        margin-bottom: 5px;
        font-size: 1 em;
        text-align: justify;
    }
`;
