import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
`;


