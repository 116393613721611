import styled from "styled-components";

export const RowProjectTable = styled.tr`
    @media only screen and (max-width: 1248px) {
        .c1 {
            img {
                width: 100px;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        .c1 {
            img {
                width: 80px;
            }
        }
    }
    @media only screen and (max-width: 815px) {
        .c1 {
            img {
                width: 60px;
            }
        }
    }
`;
