import React from "react";
import { Wrapper, Label } from "./loading.css";
import { BiLoader } from "react-icons/bi";
const Loading = (props) => {
    return (
        <Wrapper {...props}>
            <>
                <BiLoader /> <Label {...props}>{props.title}</Label>
            </>
        </Wrapper>
    );
};

export default Loading;
