import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 500px;
    margin: 150px auto;
    border-radius: 15px;
    border: 2px solid white;
    color: white;
    p {
        font-size: 1.5em;
        color: white;

        svg {
            font-size: 1.5em;
            margin-right: 7px;
        }

        &.error {
            display: flex;
            color: coral;
        }
    }
    label {
        margin-top: 10px;
        font-size: 1.2em;
        color: white;
    }
    input {
        color: white;
        background-color: ${(props) => props.theme.color.bg};
        :focus {
            background-color: ${(props) => props.theme.color.main.dark};
        }
    }
    form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        color: ${(props) => props.theme.color.main.normal};
    }
`;
