import React from "react";

import { Wrapper } from "./project.css";

const Project = ({ className, project }) => {
    const { id, title, thumbnail } = project;
    return (
        <Wrapper to={`/projekt/${id}`} className={className}>
            <div className="imgBx">
                <img
                    src={`${process.env.REACT_APP_API_URL}${
                        thumbnail === null
                            ? "galery/architect-thumbnail.jpeg"
                            : thumbnail
                    }`}
                    alt="img"
                />
            </div>
            <p>{title}</p>
        </Wrapper>
    );
};

export default Project;
