import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 2px solid white;
    margin-bottom: 10px;
    /* padding:  0 0 0; */
    .content {
        /* margin-top: 20px; */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    &:nth-child(1) {
        border-top: none;
    }
`;

export const Footer = styled.div`
    /* width: 100%; */
    padding: 10px 50px;
    margin: 20px 0px 0 0;
    display: flex;
    /* align-items: end; */
    justify-content: flex-end;
    color: white;
    font-size: 1.5rem;
    font-weight: 200;
    text-transform: uppercase;
    svg {
        font-size: 1.5rem;
        margin: 5px 10px 0 0;
    }
`;

export const MyNavLink = styled(NavLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: color 0.3s ease-in-out;
    &:hover {
        color: ${(props) => props.theme.color.main.normal};
    }
`;
